import { FormEvent, MouseEvent } from "react";

import {
  useApisMembersEmployeeSurveysIndex,
  useArray,
  useProvidersEmployeeLayout,
} from "~/hooks";

import { EmployeeSurveyType } from "~/domains";

type ReturnType = {
  employeeSurveys: EmployeeSurveyType[];
  hasNextPage?: boolean;
  isLoading: boolean;
  fetchNextPage: () => void;
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const useSearchMembersEmployeeSurveys = (): ReturnType => {
  const { setFalseRightSidebarMain } = useProvidersEmployeeLayout();
  const { items: items, setItems: setItems } = useArray<EmployeeSurveyType>([]);

  const {
    isFetching: isFetching,
    fetchNextPage: fetchNextPage,
    hasNextPage: hasNextPage,
  } = useApisMembersEmployeeSurveysIndex({
    config: {
      onSuccess: (data) => {
        setItems(data.pages.map((page) => page.employeeSurveys).flat());
      },
    },
  });

  const onSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFalseRightSidebarMain();
  };

  const onConditionReset = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setFalseRightSidebarMain();
  };

  return {
    employeeSurveys: items,
    hasNextPage: hasNextPage,
    isLoading: isFetching,
    fetchNextPage: fetchNextPage,
    onSearchSubmit,
    onConditionReset,
  };
};
