import React, { FC, ClipboardEvent } from "react";

import ReactDropzone, {
  FileRejection,
  FileError,
  ErrorCode,
} from "react-dropzone";

import { convertByte } from "~/utils";

import { ButtonWithIcon, Icon, Label } from "~/components/atoms";

import { FILE_MAX_BYTE_SIZE, ACCEPT_IMAGE_FILE_TYPES } from "~/constants/file";

type PropsType = {
  noClick?: boolean;
  fileClassName?: string;
  multiple?: boolean;
  onDropFiles: (acceptedFiles: File[], fileRejections: FileRejection[]) => void;
  onRemoveFile?: () => void;
  acceptFileTypes?: Record<string, string[]>;
  label?: string;
  required?: boolean;
  maxFileByte?: number;
  minFileByte?: number;
  readonly?: boolean;
  maxFiles?: number;
  fileName?: string;
  noFilesText: string;
};

export const DropzoneFileFieldWithFile: FC<PropsType> = ({
  noClick = false,
  multiple = false,
  onDropFiles,
  onRemoveFile,
  acceptFileTypes = ACCEPT_IMAGE_FILE_TYPES,
  label,
  required = false,
  maxFileByte = FILE_MAX_BYTE_SIZE,
  minFileByte = 0,
  fileClassName,
  readonly = false,
  fileName,
  maxFiles,
  noFilesText,
}: PropsType) => {
  const jaErrorMessage = (error: FileError) => {
    switch (error.code as ErrorCode) {
      case ErrorCode.FileInvalidType:
        return `アップロードできるファイルの拡張子は ${Object.values(
          acceptFileTypes,
        ).join(",")} です`;
      case ErrorCode.FileTooLarge:
        return `アップロードできる最大ファイルサイズは${convertByte(
          maxFileByte,
        )}です`;
      case ErrorCode.FileTooSmall:
        return `アップロードできる最小ファイルサイズは${convertByte(
          minFileByte,
        )}です`;
      case ErrorCode.TooManyFiles:
        return `アップロードできるファイルは${maxFiles || 1}つです`;
      default:
        return error.message;
    }
  };

  const jaFileRejections = (fileRejections: FileRejection[]) => {
    return fileRejections.map((reject) => ({
      file: reject.file,
      errors: reject.errors.map((error) => ({
        code: error.code,
        message: jaErrorMessage(error),
      })),
    }));
  };

  // ファイルの拡張子を取得するヘルパー関数
  const getFileExtension = (file: File): string =>
    `.${file.name.split(".").pop()?.toLowerCase() || ""}`;

  // ペーストされたファイルを処理する関数
  const handlePastedFile = (
    file: File,
    acceptedFiles: File[],
    fileRejections: FileRejection[],
  ): void => {
    const acceptedFileValues = Object.values(acceptFileTypes).flat();

    if (acceptedFileValues.includes(getFileExtension(file))) {
      acceptedFiles.push(file);
    } else {
      fileRejections.push({
        file,
        errors: [
          {
            code: ErrorCode.FileInvalidType,
            message: `アップロードできるファイルの拡張子は ${acceptedFileValues.join(
              ",",
            )} です`,
          },
        ],
      });
    }
  };

  const handlePaste = (
    e: ClipboardEvent,
    callback: (acceptedFiles: File[], fileRejections: FileRejection[]) => void,
  ) => {
    const acceptedFiles: File[] = [];
    const fileRejections: FileRejection[] = [];

    Array.from(e.clipboardData.items)
      .filter((item) => item.kind === "file" && item.getAsFile())
      .forEach((item) =>
        handlePastedFile(
          item.getAsFile() as File,
          acceptedFiles,
          fileRejections,
        ),
      );

    callback(acceptedFiles, fileRejections);
  };

  const handleRemoveFile = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const isConfirm = confirm("本当に削除しますか？");
    if (!isConfirm) return;

    onRemoveFile && onRemoveFile();
  };

  return (
    <div>
      {label && (
        <Label
          htmlFor=""
          labelText={label}
          required={required}
          className="block"
        />
      )}
      <ReactDropzone
        maxFiles={maxFiles}
        noClick={noClick}
        disabled={readonly}
        onDrop={(acceptedFiles, fileRejections) =>
          onDropFiles(acceptedFiles, jaFileRejections(fileRejections))
        }
        accept={acceptFileTypes}
        multiple={multiple}
        maxSize={maxFileByte}
      >
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div
            className={`${label && "mt-1.5"} ${
              isDragActive && "bg-primary-200"
            } ${fileClassName}`}
            {...getRootProps({
              onPaste: (e) =>
                handlePaste(e, (acceptedFiles, fileRejections) =>
                  onDropFiles(acceptedFiles, jaFileRejections(fileRejections)),
                ),
            })}
          >
            <input {...getInputProps()} />
            <Icon
              icon="ioImageOutline"
              size="1.25rem"
              className="shrink-0"
              color="text-gray-500"
            />
            <p className="ml-2">{fileName ?? noFilesText}</p>
            {onRemoveFile && fileName && !readonly && (
              <ButtonWithIcon
                icon={{
                  icon: "ioCloseOutline",
                  size: "1.25rem",
                  color: "text-gray-500",
                }}
                onClick={handleRemoveFile}
                disabled={readonly}
                className="ml-4"
              />
            )}
          </div>
        )}
      </ReactDropzone>
    </div>
  );
};
