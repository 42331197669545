import React, { FC } from "react";

import { formatDateTime } from "~/libs";

import { useProvidersCurrentEmployee } from "~/hooks";

import { MyRoutes } from "~/config/Paths";

import { SidebarLink } from "./SidebarLink";

import { ApiMembersUnreadNotificationCountsType } from "~/domains";

const today = formatDateTime(new Date(), "yyyy-MM-dd");

type PropsType = {
  breadCrumbText: string;
  unreadNotification?: ApiMembersUnreadNotificationCountsType;
};

export const MemberSidebar: FC<PropsType> = ({
  breadCrumbText,
  unreadNotification,
}: PropsType) => {
  const { currentEmployee } = useProvidersCurrentEmployee();
  const defaultBusinessSectionId =
    currentEmployee?.businessSections[0]?.id || "";

  const navs = [
    {
      label: "インサイト",
      href: MyRoutes.Members.insightPostsIndex(),
    },
    {
      label: "チャレンジ",
      href: MyRoutes.Members.focusChallengesIndex(),
    },
    {
      label: "チェックアウト",
      href: MyRoutes.Members.inputEmployeeSurveysIndex({
        q: { businessSectionId: defaultBusinessSectionId },
      }),
    },
    {
      label: "ナレッジシェア",
      href: MyRoutes.Members.knowledgeInsightPostsFromTeamsIndex(),
    },
    {
      label: "本部の発信",
      href: MyRoutes.Members.knowledgeInsightPostsFromManagersIndex({
        employeeRole: "owner",
      }),
    },
    {
      label: "部署の発信",
      href: MyRoutes.Members.knowledgeInsightPostsFromManagersIndex({
        employeeRole: "manager",
      }),
    },
    {
      label: "資料・ツール",
      href: `/document_file_category/owner?business_section_id=${defaultBusinessSectionId}`,
    },
    {
      label: "スキルマップ",
      href: MyRoutes.Members.skillEvaluationStandardSummaryCategoriesIndex({
        q: { businessSectionId: defaultBusinessSectionId },
      }),
    },
    {
      label: "ブログ", // 元々は「ブログ」
      href: `/sales_concept_category/owner?business_section_id=${defaultBusinessSectionId}`,
    },
    {
      label: "Q&A",
      href: `/question_answer_category/owner?business_section_id=${defaultBusinessSectionId}`,
    },
    {
      label: "1on1・MTG",
      href: MyRoutes.Members.meetingNotesNew(),
    },
    {
      label: "KPI・KGI",
      href: `/kpi_daily_performances/${today}?business_section_id=${defaultBusinessSectionId}`,
    },
    {
      label: "ロールプレイング",
      href: MyRoutes.Members.roleplayingConditionsNew(),
    },
    {
      label: "改善・相談",
      href: MyRoutes.Members.kaizenIdeasIndex(),
    },
    {
      label: "キャンパス",
      href: MyRoutes.Members.privatePersonalMemosIndex(),
    },
  ];

  return (
    <>
      {navs.map((nav) => (
        <SidebarLink
          key={nav.label}
          label={nav.label}
          href={nav.href}
          breadCrumbText={breadCrumbText}
          unreadNotification={unreadNotification}
        />
      ))}
    </>
  );
};
