import React, { ChangeEvent, MouseEvent, FormEvent, FC } from "react";

import { FileRejection } from "react-dropzone";
import { toast } from "react-toastify";

import { convertToDropdownOption } from "~/utils";

import { FormSalesConceptType, useBoolean } from "~/hooks";

import { Form, DropzoneFileFieldWithFile } from "~/components/atoms";
import {
  EditAndFormSubmitButton,
  LabelWithTextField,
  LabelWithMultipleDropdownField,
  FolderIconWithTitle,
  Thumbnail,
} from "~/components/molecules";
import { BlogEditor } from "~/components/organisms";

import { MultiValueType, OptionType, EditorStateType } from "~/domains";

import { FILE_MAX_BYTE_SIZE, ACCEPT_IMAGE_FILE_TYPES } from "~/constants/file";

type PropsType = {
  isSubmitting: boolean;
  defaultAccordionOpen: boolean;
  salesConcept: FormSalesConceptType;
  selectableSalesConceptTagOptions: OptionType[];
  selectableProvidingServiceOptions: OptionType[];
  onChangeTitle: (newValue: string) => void;
  onChangeContent: (newValue: EditorStateType) => void;
  onChangeTopImage: (newValue: File | undefined) => void;
  handleTagNameCreate: (newValue: string) => void;
  onChangeTags: (newValue: MultiValueType<OptionType>) => void;
  onChangeProvidingServices: (newValue: MultiValueType<OptionType>) => void;
  removeSalesConcept: () => void;
  saveSalesConcept: () => void;
  onChangeSetNotReadOnly: () => void;
  onChangeSetReadOnly: () => void;
};

export const SalesConceptForm: FC<PropsType> = ({
  isSubmitting,
  defaultAccordionOpen,
  salesConcept,
  selectableSalesConceptTagOptions,
  selectableProvidingServiceOptions,
  removeSalesConcept,
  saveSalesConcept,
  onChangeTitle,
  onChangeContent,
  onChangeTopImage,
  handleTagNameCreate,
  onChangeTags,
  onChangeProvidingServices,
  onChangeSetNotReadOnly,
  onChangeSetReadOnly,
}: PropsType) => {
  const accordionOpen = useBoolean(defaultAccordionOpen);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    saveSalesConcept();
  };

  const handleNotReadonly = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    onChangeSetNotReadOnly();
  };

  const handleRemove = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const isConfirm = confirm("本当に削除しますか？");
    if (!isConfirm) return;

    removeSalesConcept();
  };

  const handleTopImage = (
    acceptedFile: File[],
    fileRejections: FileRejection[],
  ) => {
    if (fileRejections.length) {
      fileRejections.map(({ file, errors }) => {
        if (!errors[0]) return;
        toast.error(`${file.name} - ${errors[0].message}`);
      });
      return;
    }
    acceptedFile[0] && onChangeTopImage(acceptedFile[0]);
  };
  const dropdownTagValues = salesConcept.salesConceptTags.map((tag) =>
    convertToDropdownOption(tag),
  );
  const dropdownProvidingServiceValues = salesConcept.providingServices.map(
    (service) => convertToDropdownOption(service),
  );

  const accordionToggle = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    accordionOpen.toggle();
  };

  const dropzoneClassName = `${
    salesConcept.isReadOnly
      ? "bg-secondary-300"
      : "bg-primary-200 cursor-pointer"
  } rounded-3xl text-gray-500 flex justify-center items-center py-2 px-2`;

  return (
    <Form className="space-y-4" onSubmit={onSubmit}>
      <FolderIconWithTitle
        title={salesConcept.title}
        onClick={accordionToggle}
      />
      {accordionOpen.isChecked && (
        <div className="space-y-6 ml-9">
          <LabelWithTextField
            labelText="タイトル"
            required
            type="text"
            name="title"
            value={salesConcept.title}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onChangeTitle(e.target.value)
            }
            disabled={salesConcept.isReadOnly}
            placeholder="タイトル"
          />
          <BlogEditor
            labelText="内容"
            required
            readOnly={salesConcept.isReadOnly}
            editorState={salesConcept.editorState}
            setEditorState={onChangeContent}
          />
          <DropzoneFileFieldWithFile
            readonly={salesConcept.isReadOnly}
            label="サムネイル"
            maxFileByte={FILE_MAX_BYTE_SIZE}
            acceptFileTypes={ACCEPT_IMAGE_FILE_TYPES}
            onDropFiles={handleTopImage}
            onRemoveFile={() => onChangeTopImage(undefined)}
            fileClassName={dropzoneClassName}
            fileName={salesConcept.topImage.name}
            noFilesText="JPEG / PNG"
          />
          <LabelWithMultipleDropdownField
            labelText="サービス"
            name="providingServices"
            options={selectableProvidingServiceOptions}
            value={dropdownProvidingServiceValues}
            onChange={onChangeProvidingServices}
            isDisabled={salesConcept.isReadOnly}
          />
          <LabelWithMultipleDropdownField
            labelText="タグ"
            name="salesConceptTag"
            options={selectableSalesConceptTagOptions}
            value={dropdownTagValues}
            onChange={onChangeTags}
            onCreateOption={handleTagNameCreate}
            creatable
            isDisabled={salesConcept.isReadOnly}
          />
          {salesConcept.topImage.url && (
            <div className="grid grid-cols-2 miniTablet:grid-cols-3 mt-8">
              <Thumbnail
                thumbnailImageUrl={salesConcept.topImage.url}
                attachFile={salesConcept.topImage}
                title={salesConcept.title}
                tags={salesConcept.providingServices.map(
                  (service) => service.name,
                )}
              />
            </div>
          )}
          <EditAndFormSubmitButton
            isSubmitting={isSubmitting}
            isReadOnly={salesConcept.isReadOnly}
            handleNotReadonly={handleNotReadonly}
            handleReadonly={onChangeSetReadOnly}
            handleRemove={handleRemove}
          />
        </div>
      )}
    </Form>
  );
};
