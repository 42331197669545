import React, { FC, useMemo } from "react";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

type PropsType = {
  dataSets: {
    label: string;
    value: number;
  }[];
};

// primary-900の青色をHSLに変換して、明度を徐々に上げる
const generateColors = (count: number) => {
  const baseHue = 206; // #0B85E0に相当する色相
  const baseSaturation = 80; // 彩度 (0B85E0の彩度)
  const baseLightness = 45; // 明度 (0B85E0の明度)

  return Array.from({ length: count }, (_, i) => {
    // 明度を徐々に上げる (max lightness 90%まで)
    const lightness = baseLightness + (i * (90 - baseLightness)) / count;
    return `hsl(${baseHue}, ${baseSaturation}%, ${lightness}%)`;
  });
};

export const PieChart: FC<PropsType> = ({ dataSets }) => {
  const colors = useMemo(
    () => generateColors(dataSets.length),
    [dataSets.length],
  );

  const labels = dataSets.map((dataSet) => dataSet.label);
  const data = {
    labels,
    datasets: [
      {
        labels,
        data: dataSets.map((dataSet) => dataSet.value),
        backgroundColor: colors,
        borderWidth: 0,
        hoverOffset: 4,
      },
    ],
  };
  return (
    <div className="w-full min-h-[250px]">
      <Pie data={data} width={170} />
    </div>
  );
};
