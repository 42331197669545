import React, { FC } from "react";

import { BorderedTitle } from "~/components/atoms";
import { EmployeeSurveyQuestionDetail } from "~/components/organisms/";

import { EmployeeSurveySubmissionSummary } from "./EmployeeSurveySubmissionSummary";

import { EmployeeSurveyWithQuestionSummariesType } from "~/domains";

type PropsType = {
  employeeSurvey: EmployeeSurveyWithQuestionSummariesType;
  className?: string;
};

export const EmployeeSurveysSummariesSubmissions: FC<PropsType> = ({
  employeeSurvey,
  className = "",
}: PropsType) => {
  return (
    <div className={className}>
      <BorderedTitle title={employeeSurvey.title} className="bg-white" />
      <div className="mt-12 space-y-12">
        {employeeSurvey.employeeSurveyQuestions.map((question) => (
          <div key={question.id} className="space-y-4">
            <h3 className="text-primary-600 font-semibold text-lg">
              {question.question}
            </h3>
            <EmployeeSurveyQuestionDetail
              question={question}
              answers={[]}
              options={question.employeeSurveyQuestionOptions || []}
            />
            <EmployeeSurveySubmissionSummary
              summaries={question.summaries || []}
              summaryGroupDates={question.summaryGroupDates}
              title={question.question}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
