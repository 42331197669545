import React, { FC } from "react";
import { Link } from "react-router-dom";

import { toast } from "react-toastify";

import {
  useApisAdminOperatorsCompaniesIndex,
  useApisAdminOperatorsDbBackupsCreate,
} from "~/hooks";

import { Button, GridHeaderCells, LinkButton } from "~/components/atoms";
import { AdminOperatorLayout } from "~/components/layouts";
import { GridRow, InfiniteScrollWithMoreButton } from "~/components/molecules";

export const AdminOperatorsCompaniesIndex: FC = () => {
  const breadCrumbItems = [
    {
      label: "運営者画面",
      href: "/admin_operators",
    },
    {
      label: "会社一覧",
      href: "/admin_operators",
    },
  ];

  const { data, fetchNextPage, hasNextPage } =
    useApisAdminOperatorsCompaniesIndex();

  const { mutate, isLoading: isSubmitting } =
    useApisAdminOperatorsDbBackupsCreate();

  const handleDbBackup = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const isConfirm = confirm("DBバックアップを実行しますか？");
    if (!isConfirm) return;

    mutate(
      {},
      {
        onSuccess: (data) => {
          toast.success(data.message);
        },
        onError: (error) => {
          toast.error(error.message);
        },
      },
    );
  };

  return (
    <AdminOperatorLayout breadCrumbItems={breadCrumbItems}>
      <div className="flex items-center justify-between">
        <h1 className="text-xl">会社一覧</h1>
        <div className="flex space-x-4">
          <Button
            onClick={handleDbBackup}
            text="DBバックアップ"
            color="primary"
            outline
            readonly={isSubmitting}
          />
          <LinkButton
            path="/admin_operators/companies/new"
            text="会社を新規作成する"
            color="primary"
          />
        </div>
      </div>
      <InfiniteScrollWithMoreButton
        itemsLength={data?.pages.length}
        nextFetchFunction={fetchNextPage}
        hasMore={hasNextPage}
      >
        <div className="mt-4 grid grid-cols-5">
          <GridHeaderCells
            texts={["名前", "サブドメイン", "作成日時", "更新日時", "契約状況"]}
          />
          {data?.pages.map((page) =>
            page.companies.map((company) => (
              <Link
                to={`/admin_operators/companies/${company.id}`}
                key={company.id}
                className="col-span-5 grid grid-cols-5 hover:bg-secondary-300"
              >
                <GridRow
                  lists={[
                    company.name,
                    company.subdomain,
                    company.createdAt,
                    company.updatedAt,
                    company.active ? "契約中" : "未契約",
                  ]}
                />
              </Link>
            )),
          )}
        </div>
      </InfiniteScrollWithMoreButton>
    </AdminOperatorLayout>
  );
};
