import React, { FC, useId } from "react";

import {
  BackgroundThumbnailImage,
  DropDownField,
  TextArea,
  TextField,
} from "~/components/atoms";
import {
  LabelWithCheckBox,
  LabelWithRadioButton,
} from "~/components/molecules";

import {
  EmployeeSurveyQuestionType,
  FormEmployeeSurveySubmissionAnswerType,
} from "~/domains";

type PropsType = {
  questionId: string;
  options: { id: string; option: string }[];
  answers: FormEmployeeSurveySubmissionAnswerType[];
  onChangeAnswer?: (value: string, questionId: string) => void;
};

const RadioButtonQuestion: FC<PropsType> = ({
  questionId,
  options,
  answers,
  onChangeAnswer,
}) => {
  const uniqueId = useId();
  return (
    <div className="space-y-4">
      {options.map((option) => (
        <LabelWithRadioButton
          key={option.id}
          labelText={option.option}
          disabled={!onChangeAnswer}
          name={`question-radiobutton-${questionId}-option-${option.id}-${uniqueId}`}
          value={option.id}
          checked={answers.some(
            (a) =>
              a.employeeSurveyQuestionId === questionId &&
              a.employeeSurveyQuestionOptionId === option.id,
          )}
          onChange={(e) => onChangeAnswer?.(e.target.value, questionId)}
          className="w-5 h-5"
        />
      ))}
    </div>
  );
};

const CheckBoxQuestion: FC<PropsType> = ({
  questionId,
  options,
  answers,
  onChangeAnswer,
}) => {
  const uniqueId = useId();
  return (
    <div className="space-y-4">
      {options.map((option) => (
        <LabelWithCheckBox
          key={option.id}
          labelText={option.option}
          name={`question-checkbox-${questionId}-option-${option.id}-${uniqueId}`}
          value={option.id}
          labelEnd
          disabled={!onChangeAnswer}
          checked={answers.some(
            (a) =>
              a.employeeSurveyQuestionId === questionId &&
              a.employeeSurveyQuestionOptionId === option.id,
          )}
          onChange={(e) => onChangeAnswer?.(e.target.value, questionId)}
        />
      ))}
    </div>
  );
};

const SelectBoxQuestion: FC<PropsType> = ({
  questionId,
  options,
  answers,
  onChangeAnswer,
}) => {
  const uniqueId = useId();
  return (
    <DropDownField
      isClearable={false}
      name={`question-select-${questionId}-${uniqueId}`}
      placeholder="選択してください"
      options={options.map((option) => ({
        value: option.id,
        label: option.option,
      }))}
      isDisabled={!onChangeAnswer}
      value={{
        value:
          answers.find((a) => a.employeeSurveyQuestionId === questionId)
            ?.employeeSurveyQuestionOptionId || "",
        label:
          options.find(
            (option) =>
              option.id ===
              answers.find((a) => a.employeeSurveyQuestionId === questionId)
                ?.employeeSurveyQuestionOptionId,
          )?.option || "",
      }}
      onChange={(newValue) =>
        onChangeAnswer?.(newValue?.value || "", questionId)
      }
    />
  );
};

const TextFieldQuestion: FC<PropsType> = ({
  answers,
  questionId,
  onChangeAnswer,
}) => {
  const uniqueId = useId();
  return (
    <TextField
      type="text"
      name={`question-${questionId}-${uniqueId}`}
      disabled={!onChangeAnswer}
      value={
        answers.find((a) => a.employeeSurveyQuestionId === questionId)
          ?.answer || ""
      }
      placeholder="回答を入力"
      onlyBottomBorder
      onChange={(e) => onChangeAnswer?.(e.target.value, questionId)}
    />
  );
};

const TextAreaQuestion: FC<PropsType> = ({
  answers,
  questionId,
  onChangeAnswer,
}) => {
  const uniqueId = useId();
  return (
    <TextArea
      name={`question-text-${questionId}-${uniqueId}`}
      disabled={!onChangeAnswer}
      value={
        answers.find((a) => a.employeeSurveyQuestionId === questionId)
          ?.answer || ""
      }
      placeholder="回答を入力"
      onChange={(e) => onChangeAnswer?.(e.target.value, questionId)}
    />
  );
};

type QuestionPropsType = Omit<PropsType, "questionId"> & {
  question: EmployeeSurveyQuestionType;
};

export const EmployeeSurveyQuestionDetail: FC<QuestionPropsType> = ({
  question,
  ...props
}) => {
  const Field = {
    radio_button: <RadioButtonQuestion questionId={question.id} {...props} />,
    check_box: <CheckBoxQuestion questionId={question.id} {...props} />,
    select_box: <SelectBoxQuestion questionId={question.id} {...props} />,
    text_field: <TextFieldQuestion questionId={question.id} {...props} />,
    text_area: <TextAreaQuestion questionId={question.id} {...props} />,
  }[question.questionType.type];

  return (
    <div className="w-full bg-white pt-4 pb-7 px-6 rounded space-y-4">
      <h4 className="text-secondary-900 font-semibold">{question.question}</h4>
      {question.file && (
        <div className="flex justify-center relative">
          <BackgroundThumbnailImage
            bgImageUrl={question.file.url}
            className="w-full"
          />
        </div>
      )}
      {Field}
    </div>
  );
};
