import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient, ApiResponseError } from "~/utils";

import { ApiMembersEmployeeSurveySubmissionsAiResponseShowResponseType } from "~/domains";

type RequestDataType = {
  employeeSurveySubmissionId: string;
};

const request = async ({
  employeeSurveySubmissionId,
}: RequestDataType): Promise<ApiMembersEmployeeSurveySubmissionsAiResponseShowResponseType> => {
  const response =
    await new ApiClient().get<ApiMembersEmployeeSurveySubmissionsAiResponseShowResponseType>(
      `/api/members/employee_survey_submissions/${employeeSurveySubmissionId}/ai_response`,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  employeeSurveySubmissionId: string;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisMembersEmployeeSurveySubmissionsAiResponsesShow = ({
  employeeSurveySubmissionId,
  config = {},
}: PropsType): UseQueryResult<ApiMembersEmployeeSurveySubmissionsAiResponseShowResponseType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: [
      "ApisMembersEmployeeSurveySubmissionsAiResponses",
      employeeSurveySubmissionId,
    ],
    queryFn: () => request({ employeeSurveySubmissionId }),
    useErrorBoundary: (error) => {
      return error instanceof ApiResponseError && error.status >= 500;
    },
  });
};
