import React, {
  FC,
  forwardRef,
  LegacyRef,
  MouseEventHandler,
  createElement,
} from "react";

import { isSameDay, isBefore, isAfter, isToday } from "date-fns";
import { ja } from "date-fns/locale/ja";
import ReactDatePicker, { registerLocale } from "react-datepicker";

import { toDateTimeString } from "~/libs";

import { Icon, Label, RequiredHiddenField } from "~/components/atoms";

import "react-datepicker/dist/react-datepicker.css";
import { FormSizes } from "~/constants/buttonAndForm";

registerLocale("ja", ja);

export type RangeDatePropsType = Date | [Date | null, Date | null] | null;
type PropsType = {
  labelText?: string;
  required?: boolean;
  startDate: Date | null;
  endDate: Date | null;
  minDate?: Date;
  maxDate?: Date;
  readOnly?: boolean;
  className?: string;
  wrapperClassName?: string;
  onChange?: (date: RangeDatePropsType) => void;
  size?: keyof typeof FormSizes;
};

type ForwardRefProps = {
  onClick: MouseEventHandler<HTMLDivElement>;
  className: string;
  value: string;
};

export const RangeDatePicker: FC<PropsType> = ({
  labelText,
  required = false,
  startDate,
  endDate,
  minDate,
  maxDate,
  readOnly = false,
  className = "",
  wrapperClassName = "",
  size = "sm",
  onChange,
}: PropsType) => {
  const CustomInput = forwardRef(
    (
      { onClick, className, value }: ForwardRefProps,
      ref: LegacyRef<HTMLDivElement>,
    ) => {
      return (
        <div ref={ref} onClick={onClick} className={className}>
          <Icon icon="ioCalendarClearOutline" size="1.5rem" />
          <span className="ml-2">{value}</span>
        </div>
      );
    },
  );
  CustomInput.displayName = "CustomInput";
  const strValue =
    startDate && endDate
      ? `${toDateTimeString(startDate)}, ${toDateTimeString(endDate)}`
      : "";

  const dayClassName = (date: Date) => {
    if (!startDate && !endDate && isToday(date))
      return "hover:bg-primary-800 bg-primary-600 border-primary-600 text-white";
    if (!startDate || !endDate) return null;
    if (isSameDay(date, startDate) || isSameDay(date, endDate))
      return "hover:bg-primary-800 bg-primary-600 border-primary-600 text-white";
    if (isBefore(date, endDate) && isAfter(date, startDate))
      return "hover:bg-primary-800 bg-primary-600 border-primary-600 text-white";
    return null;
  };

  return (
    <div className={wrapperClassName}>
      {labelText && (
        <Label
          labelText={labelText}
          required={required}
          className="block"
          htmlFor="dateRange"
        />
      )}
      <div className={`relative ${labelText ? "mt-1.5" : ""}`}>
        <ReactDatePicker
          locale="ja"
          minDate={minDate}
          maxDate={maxDate}
          startDate={startDate}
          endDate={endDate}
          onChange={(update) => onChange && onChange(update)}
          dateFormat="yyyy/MM/dd"
          selectsRange
          isClearable={!readOnly}
          disabled={readOnly}
          calendarStartDay={1}
          popperClassName="z-layout"
          wrapperClassName="w-full"
          className={`${
            FormSizes[size]
          } w-full flex items-center border border-solid border-secondary-400 rounded ${
            readOnly
              ? "bg-secondary-300 text-gray-600"
              : "cursor-pointer bg-white"
          } ${className}`}
          dayClassName={(date) => dayClassName(date)}
          customInput={createElement(CustomInput)}
        />
        {required && <RequiredHiddenField value={strValue} />}
      </div>
    </div>
  );
};
