import React, { FC } from "react";

type PropsType = {
  title: string;
  className?: string;
};

export const BorderedTitle: FC<PropsType> = ({
  title,
  className = "",
}: PropsType) => {
  return (
    <h2
      className={`text-secondary-600 font-semibold text-center w-full text-lg border border-solid border-secondary-400 py-2 px-3 rounded ${className}`}
    >
      {title}
    </h2>
  );
};
