import React, { FC, MouseEvent } from "react";
import { useParams } from "react-router-dom";

import { toast } from "react-toastify";

import { currentFormatTimestamp } from "~/libs";

import { arrayToCsv, downloadBlob } from "~/utils";

import {
  useApisManagersEmployeeSurveysAiSummaryCreate,
  useApisManagersEmployeeSurveysAiSummaryShow,
  useApisManagersEmployeeSurveysSubmissionSubmissionsCsvShow,
  useApisManagersEmployeeSurveysSubmissionSummaryShow,
  useDateRange,
  useDropdown,
  useInput,
} from "~/hooks";

import { Button, Label, RightSidebarTitle, TextArea } from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { LabelWithDropDownField, TitleWithChild } from "~/components/molecules";
import {
  EmployeeSurveysSummariesSubmissions,
  SelectDateRangeButtons,
} from "~/components/organisms";

export const ManagersEmployeeSurveysSummarySubmissionsShow: FC = () => {
  const { id = "" } = useParams<{ id: string }>();
  const [
    { startDate, endDate, formattedStartDate, formattedEndDate, onChange },
  ] = useDateRange([null, null]);
  const [summeredText, setSummeredText] = useInput("");
  useApisManagersEmployeeSurveysAiSummaryShow({
    employeeSurveyId: id,
    config: {
      enabled: !summeredText.value && !!id,
      onSuccess: (data) => {
        setSummeredText(data.employeeSurveyAiSummary?.summary || "");
      },
    },
  });

  const { mutate: createAiSummary, isLoading: isCreatingAiSummary } =
    useApisManagersEmployeeSurveysAiSummaryCreate();

  const [
    {
      dropdownValue,
      handleSetSelectableOption,
      dropdownSelectableValue,
      onChange: onChangeDropdown,
    },
  ] = useDropdown();

  const { data: fetchShowData } =
    useApisManagersEmployeeSurveysSubmissionSummaryShow({
      employeeSurveyId: id,
      params: {
        startDate: formattedStartDate || "",
        endDate: formattedEndDate || "",
        employeeId: dropdownValue?.value,
      },
      config: {
        onSuccess: (data) => {
          handleSetSelectableOption(data.selectableEmployees);
        },
      },
    });

  const { data: csvData } =
    useApisManagersEmployeeSurveysSubmissionSubmissionsCsvShow({
      employeeSurveyId: id,
    });

  const handleExportCsv = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!csvData) {
      return;
    }

    downloadBlob(
      arrayToCsv([[...csvData.csvHeaders], ...csvData.csvData]),
      `checkout-${currentFormatTimestamp}.csv`,
    );
  };

  const handleAiSummary = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    createAiSummary(
      {
        employeeSurveyId: id,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          setSummeredText(data.employeeSurveyAiSummary.summary);
        },
      },
    );
  };

  const breadCrumbItems = [
    { label: "チェックアウト作成", href: "/managers/employee_surveys" },
    {
      label: "分析",
      href: `/managers/employee_surveys/${id}/summary/submissions`,
    },
  ];

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent
        withRightSidebar
        className="bg-primary-200"
        navigationClassName="!bg-primary-200"
      >
        <Label
          labelText="期間指定"
          className="mt-3.5"
          required={false}
          htmlFor="dateRange"
        />
        <SelectDateRangeButtons
          startDate={startDate}
          endDate={endDate}
          onChange={onChange}
          className="mt-3.5"
        />
        <LabelWithDropDownField
          labelText="ユーザー"
          name="employee"
          required
          options={dropdownSelectableValue}
          value={dropdownValue}
          onChange={onChangeDropdown}
          wrapperClass="mt-8"
        />
        {fetchShowData && (
          <EmployeeSurveysSummariesSubmissions
            employeeSurvey={fetchShowData.employeeSurvey}
            className="mt-8"
          />
        )}
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar wrapperClassName="bg-primary-200">
        <RightSidebarTitle title="分析メニュー" />
        <div className="px-4 py-3 space-y-6">
          <TitleWithChild title="CSV">
            <Button
              text="CSVエクスポート"
              color={"gray"}
              outline
              onClick={handleExportCsv}
              className="w-full"
              readonly={!csvData}
            />
          </TitleWithChild>
          <TitleWithChild title="チェックアウト">
            <Button
              text={isCreatingAiSummary ? "要約中..." : "要約する"}
              color={"gray"}
              outline
              onClick={handleAiSummary}
              className="w-full"
              readonly={isCreatingAiSummary}
            />
            <TextArea
              name="aiSummary"
              placeholder="要約内容"
              className="mt-2"
              rows={4}
              disabled
              value={summeredText.value}
            />
          </TitleWithChild>
        </div>
      </EmployeeLayoutRightSidebar>
    </EmployeeLayout>
  );
};
