import React, { FC } from "react";

import { useBoolean } from "~/hooks";

import { ModalWrapper } from "~/components/atoms";

import { EmployeeSurveyQuestionSummaryType } from "~/domains";

type PropsType = {
  summary: EmployeeSurveyQuestionSummaryType;
  className?: string;
};

export const EmployeeSurveysSummariesAnswers: FC<PropsType> = ({
  className = "",
  summary,
}) => {
  const { isChecked: open, setTrue, setFalse } = useBoolean(false);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const employeeNames: string[] = summary.employeeNames;
  console.log({ summary });
  return (
    <div className={`rounded p-6 ${className}`}>
      <h4 className="text-secondary-600 font-semibold border-b border-secondary-400 pb-2 whitespace-pre-wrap break-words">
        {summary.answer}
      </h4>
      <div className="relative  mt-2">
        <button
          onClick={() => setTrue()}
          disabled={summary.count < 1}
          type="button"
          className="text-primary-600 font-semibold"
        >
          {summary.count}件の回答
        </button>
        {open && (
          <>
            <ModalWrapper isWhite onClose={setFalse} />
            <ul
              className="absolute left-0 z-modal min-w-max shadow
                          p-3.5 bg-white border border-solid
                          border-gray-200 rounded-lg top-full space-y-4"
            >
              {employeeNames.map((employee, index) => (
                <li key={index}>{employee}</li>
              ))}
            </ul>
          </>
        )}
      </div>
    </div>
  );
};
