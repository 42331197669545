import React, { useState, FC, MouseEvent } from "react";

import { QueryObserverResult } from "@tanstack/react-query";
import { toast } from "react-toastify";

import {
  useApisMembersEmployeeSurveySubmissionsEmployeeSurveySubmissionRepliesCreate,
  useEditorState,
  useEditorStateLinkContentGetter,
  useApisMembersEmployeeSurveySubmissionsAiResponsesShow,
  useApisMembersEmployeeSurveySubmissionsAiResponsesCreate,
  useProvidersCurrentEmployee,
} from "~/hooks";

import { Button } from "~/components/atoms";
import {
  EmployeeSurveySubmissionReplyDetailWithEditForm,
  EmployeeSurveySubmissionDetail,
  TextEditor,
} from "~/components/organisms";

import {
  EmployeeSurveySubmissionType,
  EmployeeSurveySubmissionReplyType,
  MentionSuggestEmployeeType,
  PreviewWithFileType,
  ApiMemberEmployeeSurveySubmissionRepliesIndexResponseType,
} from "~/domains";

type PropsType = {
  highlightEmployeeSurveySubmissionReplyId?: string;
  employeeSurveySubmission: EmployeeSurveySubmissionType;
  employeeSurveySubmissionReplies: EmployeeSurveySubmissionReplyType[];
  mentions: MentionSuggestEmployeeType[];
  className?: string;
  refetch: () => Promise<
    QueryObserverResult<
      ApiMemberEmployeeSurveySubmissionRepliesIndexResponseType,
      unknown
    >
  >;
};

export const EmployeeSurveySubmissionRepliesWithEmployeeSurveySubmission: FC<
  PropsType
> = ({
  highlightEmployeeSurveySubmissionReplyId = "",
  employeeSurveySubmission,
  employeeSurveySubmissionReplies,
  mentions,
  className = "",
  refetch,
}: PropsType) => {
  const { currentEmployee } = useProvidersCurrentEmployee();
  const [files, setFiles] = useState<PreviewWithFileType[]>([]);
  const [
    {
      value: editorState,
      onChange: setEditorState,
      jsonContent,
      hasText,
      setPlainText,
    },
  ] = useEditorState();
  const { fetchedData } = useEditorStateLinkContentGetter({
    watchEditorState: editorState,
    isWatch: true,
  });

  const { mutate, isLoading } =
    useApisMembersEmployeeSurveySubmissionsEmployeeSurveySubmissionRepliesCreate();

  const isMyEmployeeSurveySubmission =
    employeeSurveySubmission.employee.id === currentEmployee?.id;

  const {
    data: employeeSurveySubmissionsAiResponseData,
    isFetching,
    refetch: aiResponseRefetch,
  } = useApisMembersEmployeeSurveySubmissionsAiResponsesShow({
    employeeSurveySubmissionId: employeeSurveySubmission.id,
    config: {
      enabled: isMyEmployeeSurveySubmission,
    },
  });
  const { mutate: aiResponseMutate, isLoading: isAiResponseSubmitting } =
    useApisMembersEmployeeSurveySubmissionsAiResponsesCreate();

  const handleSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!hasText) {
      toast.error("テキストが空です");
      return;
    }
    const body = {
      content: jsonContent,
      attachFiles: files,
      quotePostId: fetchedData?.id,
    };

    mutate(
      {
        employeeSurveySubmissionId: employeeSurveySubmission.id,
        body,
      },
      {
        onSuccess: async () => {
          setPlainText("");
          setFiles([]);
          await refetch();
        },
      },
    );
  };

  return (
    <div className={className}>
      <div className="space-y-6">
        <EmployeeSurveySubmissionDetail
          employeeSurveySubmission={employeeSurveySubmission}
          openReply={() => refetch()}
        />
        {isMyEmployeeSurveySubmission &&
          !isFetching &&
          employeeSurveySubmissionsAiResponseData?.employeeSurveySubmissionAiResponse && (
            <div className="p-4 bg-white whitespace-pre-wrap">
              <p className="text-lg text-center text-primary-600 font-semibold">
                AIによる回答
              </p>
              <div className="mt-2">
                {
                  employeeSurveySubmissionsAiResponseData
                    .employeeSurveySubmissionAiResponse?.answer
                }
              </div>
            </div>
          )}
        {isMyEmployeeSurveySubmission &&
          !isFetching &&
          !employeeSurveySubmissionsAiResponseData?.employeeSurveySubmissionAiResponse && (
            <Button
              text={
                isAiResponseSubmitting
                  ? "AIによる回答を取得中"
                  : "AIによる回答を見る"
              }
              color="primary"
              className="w-full"
              readonly={isAiResponseSubmitting || !isMyEmployeeSurveySubmission}
              onClick={() => {
                aiResponseMutate(
                  {
                    employeeSurveySubmissionId: employeeSurveySubmission.id,
                  },
                  {
                    onSuccess: async () => {
                      await aiResponseRefetch();
                    },
                  },
                );
              }}
            />
          )}
        <div>
          {employeeSurveySubmissionReplies.map((reply) => (
            <EmployeeSurveySubmissionReplyDetailWithEditForm
              key={reply.id}
              employeeSurveySubmissionReply={reply}
              mentions={mentions}
              refetch={refetch}
              isHighlight={
                reply.id === highlightEmployeeSurveySubmissionReplyId
              }
            />
          ))}
        </div>
        <TextEditor
          submitButton={{
            isSubmitting: isLoading,
            onSubmit: handleSubmit,
          }}
          mentions={mentions}
          editorState={editorState}
          setEditorState={setEditorState}
          files={files}
          onChangeFiles={setFiles}
          blockQuote={fetchedData}
          isEmojiPickerTop
        />
      </div>
    </div>
  );
};
