import React, { FC, ChangeEvent } from "react";

import { FormSizes } from "~/constants/buttonAndForm";

type PropsType = {
  name: string;
  value: number | string;
  defaultValue?: number;
  autoComplete?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  min?: number;
  max?: number;
  size?: keyof typeof FormSizes;
  onlyBottomBorder?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const NumberField: FC<PropsType> = ({
  name,
  value,
  defaultValue,
  autoComplete,
  placeholder = "",
  required = false,
  disabled = false,
  className = "",
  onlyBottomBorder = false,
  min,
  max,
  onChange,
  size = "sm",
}: PropsType) => {
  return (
    <input
      type="number"
      inputMode="numeric"
      pattern="/^([1-9]\d*|0)$/"
      name={name}
      value={value}
      defaultValue={defaultValue}
      autoComplete={autoComplete}
      placeholder={placeholder}
      required={required}
      disabled={disabled}
      min={min}
      max={max}
      className={`${FormSizes[size]} ${
        onlyBottomBorder ? "border-b rounded-none" : "border rounded"
      } border-solid border-secondary-400 placeholder-secondary-400  focus:outline-none focus:border-secondary-400  ${
        disabled ? "bg-secondary-300 text-secondary-600" : ""
      } ${className}`}
      onChange={onChange}
    />
  );
};
