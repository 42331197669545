import {
  ExtractFnReturnType,
  InfiniteQueryConfigType,
  useInfiniteQuery,
  UseInfiniteQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApiMembersEmployeeSurveySubmissionsIndexRequestType,
  ApiMembersEmployeeSurveySubmissionsIndexResponseType,
} from "~/domains";

type RequestDataType = {
  employeeSurveyId: string;
  params?: ApiMembersEmployeeSurveySubmissionsIndexRequestType;
  page?: number;
};

const request = async ({
  employeeSurveyId,
  params,
  page = 1,
}: RequestDataType): Promise<ApiMembersEmployeeSurveySubmissionsIndexResponseType> => {
  const response =
    await new ApiClient().get<ApiMembersEmployeeSurveySubmissionsIndexResponseType>(
      `/api/members/employee_surveys/${employeeSurveyId}/employee_survey_submissions`,
      { page, ...params },
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  employeeSurveyId: string;
  params?: ApiMembersEmployeeSurveySubmissionsIndexRequestType;
  config?: InfiniteQueryConfigType<QueryFnType>;
};

export const useApisMembersEmployeeSurveysEmployeeSurveySubmissionsIndex = ({
  employeeSurveyId,
  params,
  config = {},
}: PropsType): UseInfiniteQueryResult<ApiMembersEmployeeSurveySubmissionsIndexResponseType> => {
  return useInfiniteQuery<ExtractFnReturnType<QueryFnType>>(
    [
      "ApisMembersEmployeeSurveysEmployeeSurveySubmissionsIndex",
      employeeSurveyId,
      params,
    ],
    ({ pageParam }) => {
      return request({
        employeeSurveyId,
        params,
        page: pageParam as number | undefined,
      });
    },
    {
      ...config,
      getNextPageParam: (data) => {
        return data.pagy.hasMore ? data.pagy.currentPage + 1 : false;
      },
    },
  );
};
