import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApiManagersEmployeeSurveysShowResponseType } from "~/domains";

type RequestDataType = {
  id: string;
};

const request = async ({
  id,
}: RequestDataType): Promise<ApiManagersEmployeeSurveysShowResponseType> => {
  const response =
    await new ApiClient().get<ApiManagersEmployeeSurveysShowResponseType>(
      `/api/managers/employee_surveys/${id}`,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  id: string;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisManagersEmployeeSurveysShow = ({
  id,
  config = {},
}: PropsType): UseQueryResult<ApiManagersEmployeeSurveysShowResponseType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisManagersEmployeeSurveysShow", id],
    cacheTime: 0,
    queryFn: () => request({ id }),
  });
};
