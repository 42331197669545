import update from "immutability-helper";
import { toast } from "react-toastify";

import DefaultImage from "~/assets/images/default_1280*720.png";

import { convertToValue, convertToDropdownOption } from "~/utils";

import {
  useApisManagersDocumentFileCategoriesDisplayOrdersUpdate,
  useApisManagersDocumentFileCategoriesDocumentFilesCreate,
  useApisManagersDocumentFileCategoriesDocumentFilesDestroy,
  useApisManagersDocumentFileCategoriesDocumentFilesUpdate,
  useArray,
} from "~/hooks";

import {
  DocumentFileType,
  OptionType,
  MultiValueType,
  DocumentFileTagType,
  ProvidingServiceType,
} from "~/domains";

export type FormDocumentFileType = Pick<
  DocumentFileType,
  | "id"
  | "documentFileCategoryId"
  | "name"
  | "displayOrder"
  | "attachFile"
  | "thumbnailImage"
  | "documentFileTags"
> & {
  isNew?: boolean;
  newAttachFile?: File;
  newThumbnailImage?: File;
  isReadOnly?: boolean;
  providingServices: Pick<ProvidingServiceType, "id" | "name">[];
};

type OnChangeNamePropsType = {
  documentFile: FormDocumentFileType;
  newValue: string;
};

type OnChangeFilePropsType = {
  documentFile: FormDocumentFileType;
  newValue: File | undefined;
};

type OnChangeTagsPropsType = {
  documentFile: FormDocumentFileType;
  newValue: MultiValueType<OptionType>;
};

type ReturnType = {
  isSubmitting: boolean;
  documentFiles: FormDocumentFileType[];
  selectableDocumentFileTagOptions: OptionType[];
  selectableProvidingServiceOptions: OptionType[];
  onChangeName: ({ documentFile, newValue }: OnChangeNamePropsType) => void;
  onChangeAttachFile: ({
    documentFile,
    newValue,
  }: OnChangeFilePropsType) => void;
  onChangeThumbnailImage: ({
    documentFile,
    newValue,
  }: OnChangeFilePropsType) => void;
  addDocumentFile: () => void;
  handleTagNameCreate: ({
    documentFile,
    newValue,
  }: OnChangeNamePropsType) => void;
  onChangeTags: ({ documentFile, newValue }: OnChangeTagsPropsType) => void;
  onChangeProvidingServices: ({
    documentFile,
    newValue,
  }: OnChangeTagsPropsType) => void;
  removeDocumentFile: (documentFile: FormDocumentFileType) => void;
  saveDocumentFile: (documentFile: FormDocumentFileType) => void;
  onChangeSetNotReadOnly: (documentFile: FormDocumentFileType) => void;
  onChangeSetReadOnly: (documentFile: FormDocumentFileType) => void;
  changeDisplayOrder: (beforeIndex: number, index: number) => void;
};

type PropsType = {
  documentFileCategoryId: string;
  documentFiles: FormDocumentFileType[];
  selectableDocumentFileTags: DocumentFileTagType[];
  selectableProvidingServices: ProvidingServiceType[];
};

export function useDocumentFileForms({
  documentFileCategoryId,
  documentFiles,
  selectableDocumentFileTags,
  selectableProvidingServices,
}: PropsType): ReturnType {
  const { mutate: deleteDocumentFile } =
    useApisManagersDocumentFileCategoriesDocumentFilesDestroy();
  const { mutate: createDocumentFile, isLoading: isCreating } =
    useApisManagersDocumentFileCategoriesDocumentFilesCreate();
  const { mutate: updateDocumentFile, isLoading: isUpdating } =
    useApisManagersDocumentFileCategoriesDocumentFilesUpdate();
  const { mutate: putDocumentFileDisplayOrder } =
    useApisManagersDocumentFileCategoriesDisplayOrdersUpdate();

  const defaultItemFormat = (id: string, displayOrder: number) => {
    return {
      id: id,
      documentFileCategoryId,
      name: "資料名",
      attachFile: { name: "", url: "", contentType: "image/jpeg" },
      thumbnailImage: {
        name: "",
        url: DefaultImage as string,
        contentType: "image/jpeg",
      },
      newAttachFile: undefined,
      newThumbnailImage: undefined,
      isNew: true,
      isReadOnly: false,
      documentFileTags: [],
      providingServices: [],
      displayOrder,
    };
  };

  const { items, findAndReplace, pushItem, findAndRemove, setItems } =
    useArray<FormDocumentFileType>(
      documentFiles.length
        ? documentFiles.map((documentFile) => ({
            ...documentFile,
            isNew: false,
            isReadOnly: true,
          }))
        : [defaultItemFormat("1", 1)],
    );

  const tagOptions = useArray<OptionType>(
    selectableDocumentFileTags.map((tag) => convertToDropdownOption(tag)),
  );
  const providingServiceOptions = useArray<OptionType>(
    selectableProvidingServices.map((tag) => convertToDropdownOption(tag)),
  );

  const addItem = () => {
    const order = Math.max(...items.map((item) => item.displayOrder)) + 1;
    pushItem(defaultItemFormat(order.toString(), order));
  };

  const changeDisplayOrder = (beforeIndex: number, newIndex: number) => {
    const targetDocumentFile = items[beforeIndex];
    if (!targetDocumentFile) return;
    const newItems = update(items, {
      $splice: [
        [beforeIndex, 1],
        [newIndex, 0, targetDocumentFile],
      ],
    });

    putDocumentFileDisplayOrder(
      {
        documentFileCategoryId,
        body: generateOrderParams(newItems),
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          setItems(newItems);
        },
      },
    );
  };

  const generateOrderParams = (newItems: FormDocumentFileType[]) => {
    return newItems
      .filter((item) => !item.isNew)
      .map((item, index) => ({
        id: item.id.toString(),
        displayOrder: index + 1,
      }));
  };

  const removeDocumentFile = (documentFile: FormDocumentFileType) => {
    documentFile.isNew
      ? findAndRemove((item) => item.id === documentFile.id)
      : deleteDocumentFile(
          {
            documentFileCategoryId,
            id: documentFile.id,
          },
          {
            onSuccess: (data) => {
              findAndRemove((item) => item.id === documentFile.id);
              toast(data.message);
            },
          },
        );
  };

  const saveDocumentFile = (documentFile: FormDocumentFileType) => {
    documentFile.isNew
      ? handleCreateDocumentFile(documentFile)
      : handleUpdateDocumentFile(documentFile);
  };

  const handleCreateDocumentFile = (documentFile: FormDocumentFileType) => {
    createDocumentFile(
      {
        documentFileCategoryId,
        body: generateParams(documentFile),
      },
      {
        onSuccess: (data) => {
          onSuccessAction(documentFile, data.message, data.documentFile.id);
        },
      },
    );
  };

  const handleUpdateDocumentFile = (documentFile: FormDocumentFileType) => {
    updateDocumentFile(
      {
        documentFileCategoryId,
        id: documentFile.id,
        body: generateParams(documentFile),
      },
      {
        onSuccess: (data) => {
          onSuccessAction(documentFile, data.message, data.documentFile.id);
        },
      },
    );
  };

  const onSuccessAction = (
    documentFile: FormDocumentFileType,
    successMessage: string,
    newDocumentFileId: string,
  ) => {
    toast(successMessage);
    const newObject = {
      ...documentFile,
      id: newDocumentFileId,
      isNew: false,
      isReadOnly: true,
    };

    findAndReplace(newObject, (item) => item.id === documentFile.id);
  };

  const generateParams = (documentFile: FormDocumentFileType) => {
    return {
      name: documentFile.name,
      displayOrder: documentFile.displayOrder,
      attachFile: documentFile.newAttachFile,
      thumbnailImage: documentFile.newThumbnailImage,
      tagNames: documentFile.documentFileTags.map((tag) => tag.name),
      providingServiceIds: documentFile.providingServices.map(
        (service) => service.id,
      ),
    };
  };

  const onChangeName = ({ documentFile, newValue }: OnChangeNamePropsType) => {
    const newObject = { ...documentFile, name: newValue };

    findAndReplace(newObject, (item) => item.id === documentFile.id);
  };

  const onChangeSetNotReadOnly = (documentFile: FormDocumentFileType) => {
    const newObject = { ...documentFile, isReadOnly: false };

    findAndReplace(newObject, (item) => item.id === documentFile.id);
  };

  const onChangeSetReadOnly = (documentFile: FormDocumentFileType) => {
    const newObject = { ...documentFile, isReadOnly: true };

    findAndReplace(newObject, (item) => item.id === documentFile.id);
  };

  const randomNumber = () => {
    return Math.floor(Math.random() * 10000000).toString();
  };

  const handleTagNameCreate = ({
    documentFile,
    newValue,
  }: OnChangeNamePropsType) => {
    const randomNumberId = randomNumber();
    const newTagItem = [
      ...documentFile.documentFileTags,
      { id: randomNumberId, name: newValue },
    ];
    const newObject = { ...documentFile, documentFileTags: newTagItem };

    findAndReplace(newObject, (item) => item.id === documentFile.id);
    tagOptions.pushItem({ value: randomNumberId, label: newValue });
  };

  const onChangeTags = ({ documentFile, newValue }: OnChangeTagsPropsType) => {
    const newTagItem = newValue.map((val) => convertToValue(val));
    const newObject = { ...documentFile, documentFileTags: newTagItem };

    findAndReplace(newObject, (item) => item.id === documentFile.id);
  };

  const onChangeProvidingServices = ({
    documentFile,
    newValue,
  }: OnChangeTagsPropsType) => {
    const newProvidingServices = newValue.map((val) => convertToValue(val));
    const newObject = {
      ...documentFile,
      providingServices: newProvidingServices,
    };

    findAndReplace(newObject, (item) => item.id === documentFile.id);
  };

  const onChangeAttachFile = ({
    documentFile,
    newValue,
  }: OnChangeFilePropsType) => {
    const newObject = {
      ...documentFile,
      attachFile: fileToNewFile(newValue),
      newAttachFile: newValue,
    };

    findAndReplace(newObject, (item) => item.id === documentFile.id);
  };

  const onChangeThumbnailImage = ({
    documentFile,
    newValue,
  }: OnChangeFilePropsType) => {
    const newObject = {
      ...documentFile,
      thumbnailImage: newValue
        ? fileToNewFile(newValue)
        : {
            name: "",
            url: DefaultImage as string,
            contentType: "image/jpeg",
          },
      newThumbnailImage: newValue,
    };

    findAndReplace(newObject, (item) => item.id === documentFile.id);
  };

  const fileToNewFile = (file: File | undefined) => {
    return {
      url: file ? URL.createObjectURL(file) : "",
      name: file?.name || "",
      contentType: file?.type || "image/jpeg",
    };
  };

  return {
    isSubmitting: isCreating || isUpdating,
    documentFiles: items,
    onChangeName,
    addDocumentFile: addItem,
    removeDocumentFile,
    saveDocumentFile,
    changeDisplayOrder,
    handleTagNameCreate,
    onChangeTags,
    onChangeProvidingServices,
    onChangeAttachFile,
    onChangeThumbnailImage,
    onChangeSetNotReadOnly,
    onChangeSetReadOnly,
    selectableDocumentFileTagOptions: tagOptions.items,
    selectableProvidingServiceOptions: providingServiceOptions.items,
  };
}
