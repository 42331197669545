import { convertToRaw } from "draft-js";
import update from "immutability-helper";
import { toast } from "react-toastify";

import DefaultImage from "~/assets/images/default_1280*720.png";

import {
  convertToValue,
  convertToDropdownOption,
  rawDraftContentStateToDraftJsContent,
} from "~/utils";

import {
  useApisManagersSalesConceptCategoriesDisplayOrdersUpdate,
  useApisManagersSalesConceptCategoriesSalesConceptsCreate,
  useApisManagersSalesConceptCategoriesSalesConceptsDestroy,
  useApisManagersSalesConceptCategoriesSalesConceptsUpdate,
  useArray,
} from "~/hooks";

import {
  SalesConceptType,
  OptionType,
  MultiValueType,
  SalesConceptTagType,
  ProvidingServiceType,
  EditorStateType,
} from "~/domains";

export type FormSalesConceptType = Pick<
  SalesConceptType,
  | "id"
  | "salesConceptCategoryId"
  | "title"
  | "content"
  | "displayOrder"
  | "topImage"
  | "salesConceptTags"
> & {
  editorState?: EditorStateType;
  isNew?: boolean;
  isReadOnly?: boolean;
  newTopImage?: File;
  providingServices: Pick<ProvidingServiceType, "id" | "name">[];
};

type OnChangeTitlePropsType = {
  salesConcept: FormSalesConceptType;
  newValue: string;
};

type OnChangeContentPropsType = {
  salesConcept: FormSalesConceptType;
  newValue: EditorStateType;
};

type OnChangeFilePropsType = {
  salesConcept: FormSalesConceptType;
  newValue: File | undefined;
};

type OnChangeTagsPropsType = {
  salesConcept: FormSalesConceptType;
  newValue: MultiValueType<OptionType>;
};

type ReturnType = {
  isSubmitting: boolean;
  salesConcepts: FormSalesConceptType[];
  selectableSalesConceptTagOptions: OptionType[];
  selectableProvidingServiceOptions: OptionType[];
  onChangeTitle: ({ salesConcept, newValue }: OnChangeTitlePropsType) => void;
  onChangeContent: ({
    salesConcept,
    newValue,
  }: OnChangeContentPropsType) => void;
  onChangeTopImage: ({ salesConcept, newValue }: OnChangeFilePropsType) => void;
  addSalesConcept: () => void;
  handleTagNameCreate: ({
    salesConcept,
    newValue,
  }: OnChangeTitlePropsType) => void;
  onChangeTags: ({ salesConcept, newValue }: OnChangeTagsPropsType) => void;
  onChangeProvidingServices: ({
    salesConcept,
    newValue,
  }: OnChangeTagsPropsType) => void;
  removeSalesConcept: (salesConcept: FormSalesConceptType) => void;
  saveSalesConcept: (salesConcept: FormSalesConceptType) => void;
  onChangeSetNotReadOnly: (salesConcept: FormSalesConceptType) => void;
  onChangeSetReadOnly: (salesConcept: FormSalesConceptType) => void;
  changeDisplayOrder: (beforeIndex: number, index: number) => void;
};

type PropsType = {
  salesConceptCategoryId: string;
  salesConcepts: FormSalesConceptType[];
  selectableSalesConceptTags: SalesConceptTagType[];
  selectableProvidingServices: ProvidingServiceType[];
};

export function useSalesConceptForms({
  salesConceptCategoryId,
  salesConcepts,
  selectableSalesConceptTags,
  selectableProvidingServices,
}: PropsType): ReturnType {
  const { mutate: deleteSalesConcept } =
    useApisManagersSalesConceptCategoriesSalesConceptsDestroy();
  const { mutate: createSalesConcept, isLoading: isCreating } =
    useApisManagersSalesConceptCategoriesSalesConceptsCreate();
  const { mutate: updateSalesConcept, isLoading: isUpdating } =
    useApisManagersSalesConceptCategoriesSalesConceptsUpdate();
  const { mutate: putSalesConceptDisplayOrder } =
    useApisManagersSalesConceptCategoriesDisplayOrdersUpdate();

  const defaultItemFormat = (id: string, displayOrder: number) => {
    return {
      id: id,
      salesConceptCategoryId,
      title: "",
      topImage: {
        name: "",
        url: DefaultImage as string,
        contentType: "image/jpeg",
      },
      content: convertToRaw(
        rawDraftContentStateToDraftJsContent().getCurrentContent(),
      ),
      editorState: rawDraftContentStateToDraftJsContent(),
      isNew: true,
      isReadOnly: false,
      salesConceptTags: [],
      providingServices: [],
      displayOrder,
    };
  };

  const { items, findAndReplace, pushItem, findAndRemove, setItems } =
    useArray<FormSalesConceptType>(
      salesConcepts.length
        ? salesConcepts.map((salesConcept) => ({
            ...salesConcept,
            editorState: rawDraftContentStateToDraftJsContent(
              salesConcept.content,
            ),
            isNew: false,
            isReadOnly: true,
          }))
        : [defaultItemFormat("1", 1)],
    );

  const tagOptions = useArray<OptionType>(
    selectableSalesConceptTags.map((tag) => convertToDropdownOption(tag)),
  );
  const providingServiceOptions = useArray<OptionType>(
    selectableProvidingServices.map((tag) => convertToDropdownOption(tag)),
  );

  const addItem = () => {
    const order = Math.max(...items.map((item) => item.displayOrder)) + 1;
    pushItem(defaultItemFormat(order.toString(), order));
  };

  const changeDisplayOrder = (beforeIndex: number, newIndex: number) => {
    const targetSalesConcept = items[beforeIndex];
    if (!targetSalesConcept) return;
    const newItems = update(items, {
      $splice: [
        [beforeIndex, 1],
        [newIndex, 0, targetSalesConcept],
      ],
    });

    putSalesConceptDisplayOrder(
      {
        salesConceptCategoryId,
        body: generateOrderParams(newItems),
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          setItems(newItems);
        },
      },
    );
  };

  const generateOrderParams = (newItems: FormSalesConceptType[]) => {
    return newItems
      .filter((item) => !item.isNew)
      .map((item, index) => ({
        id: item.id.toString(),
        displayOrder: index + 1,
      }));
  };

  const removeSalesConcept = (salesConcept: FormSalesConceptType) => {
    salesConcept.isNew
      ? findAndRemove((item) => item.id === salesConcept.id)
      : deleteSalesConcept(
          {
            salesConceptCategoryId,
            id: salesConcept.id,
          },
          {
            onSuccess: (data) => {
              findAndRemove((item) => item.id === salesConcept.id);
              toast(data.message);
            },
          },
        );
  };

  const saveSalesConcept = (salesConcept: FormSalesConceptType) => {
    salesConcept.isNew
      ? handleCreateSalesConcept(salesConcept)
      : handleUpdateSalesConcept(salesConcept);
  };

  const handleCreateSalesConcept = (salesConcept: FormSalesConceptType) => {
    createSalesConcept(
      {
        salesConceptCategoryId,
        body: generateParams(salesConcept),
      },
      {
        onSuccess: (data) => {
          onSuccessAction(salesConcept, data.message, data.salesConcept.id);
        },
      },
    );
  };

  const handleUpdateSalesConcept = (salesConcept: FormSalesConceptType) => {
    updateSalesConcept(
      {
        salesConceptCategoryId,
        id: salesConcept.id,
        body: generateParams(salesConcept),
      },
      {
        onSuccess: (data) => {
          onSuccessAction(salesConcept, data.message, data.salesConcept.id);
        },
      },
    );
  };

  const onSuccessAction = (
    salesConcept: FormSalesConceptType,
    successMessage: string,
    newSalesConceptId: string,
  ) => {
    toast(successMessage);
    const newObject = {
      ...salesConcept,
      id: newSalesConceptId,
      isNew: false,
      isReadOnly: true,
    };

    findAndReplace(newObject, (item) => item.id === salesConcept.id);
  };

  const generateParams = (salesConcept: FormSalesConceptType) => {
    return {
      title: salesConcept.title,
      topImage: salesConcept.newTopImage,
      publicStatusId: 1,
      content: salesConcept.content,
      businessSectionIds: ["1"],
      tagNames: salesConcept.salesConceptTags.map((tag) => tag.name),
      providingServiceIds: salesConcept.providingServices.map(
        (service) => service.id,
      ),
      allBusinessSectionsDistribution: false,
      displayOrder: salesConcept.displayOrder,
    };
  };

  const onChangeTitle = ({
    salesConcept,
    newValue,
  }: OnChangeTitlePropsType) => {
    const newObject = { ...salesConcept, title: newValue };

    findAndReplace(newObject, (item) => item.id === salesConcept.id);
  };

  const onChangeContent = ({
    salesConcept,
    newValue,
  }: OnChangeContentPropsType) => {
    const newObject = {
      ...salesConcept,
      content: convertToRaw(newValue.getCurrentContent()),
      editorState: newValue,
    };
    findAndReplace(newObject, (item) => item.id === salesConcept.id);
  };

  const onChangeSetNotReadOnly = (salesConcept: FormSalesConceptType) => {
    const newObject = { ...salesConcept, isReadOnly: false };

    findAndReplace(newObject, (item) => item.id === salesConcept.id);
  };

  const onChangeSetReadOnly = (salesConcept: FormSalesConceptType) => {
    const newObject = { ...salesConcept, isReadOnly: true };

    findAndReplace(newObject, (item) => item.id === salesConcept.id);
  };

  const randomNumber = () => {
    return Math.floor(Math.random() * 10000000).toString();
  };

  const handleTagNameCreate = ({
    salesConcept,
    newValue,
  }: OnChangeTitlePropsType) => {
    const randomNumberId = randomNumber();
    const newTagItem = [
      ...salesConcept.salesConceptTags,
      { id: randomNumberId, name: newValue },
    ];
    const newObject = { ...salesConcept, salesConceptTags: newTagItem };

    findAndReplace(newObject, (item) => item.id === salesConcept.id);
    tagOptions.pushItem({ value: randomNumberId, label: newValue });
  };

  const onChangeTags = ({ salesConcept, newValue }: OnChangeTagsPropsType) => {
    const newTagItem = newValue.map((val) => convertToValue(val));
    const newObject = { ...salesConcept, salesConceptTags: newTagItem };

    findAndReplace(newObject, (item) => item.id === salesConcept.id);
  };

  const onChangeProvidingServices = ({
    salesConcept,
    newValue,
  }: OnChangeTagsPropsType) => {
    const newProvidingServices = newValue.map((val) => convertToValue(val));
    const newObject = {
      ...salesConcept,
      providingServices: newProvidingServices,
    };

    findAndReplace(newObject, (item) => item.id === salesConcept.id);
  };

  const onChangeTopImage = ({
    salesConcept,
    newValue,
  }: OnChangeFilePropsType) => {
    const newObject = {
      ...salesConcept,
      topImage: fileToNewFile(newValue),
      newTopImage: newValue,
    };

    findAndReplace(newObject, (item) => item.id === salesConcept.id);
  };

  const fileToNewFile = (file: File | undefined) => {
    return {
      url: file ? URL.createObjectURL(file) : (DefaultImage as string),
      name: file?.name || "",
      contentType: file?.type || "image/jpeg",
    };
  };

  return {
    isSubmitting: isCreating || isUpdating,
    salesConcepts: items,
    onChangeTitle,
    onChangeContent,
    addSalesConcept: addItem,
    removeSalesConcept,
    saveSalesConcept,
    changeDisplayOrder,
    handleTagNameCreate,
    onChangeTags,
    onChangeProvidingServices,
    onChangeTopImage,
    onChangeSetNotReadOnly,
    onChangeSetReadOnly,
    selectableSalesConceptTagOptions: tagOptions.items,
    selectableProvidingServiceOptions: providingServiceOptions.items,
  };
}
