import React, { FC } from "react";

import {
  useSearchMembersEmployeeSurveys,
  useEmployeeSurveysSlideNavigation,
  useProvidersCurrentEmployee,
} from "~/hooks";

import { GridHeaderCells } from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import {
  GridRow,
  InfiniteScrollWithMoreButton,
  OpenedMenuInGrid,
} from "~/components/molecules";

import { MyRoutes } from "~/config/Paths";

export const MembersEmployeeSurveysIndex: FC = () => {
  const { currentEmployee } = useProvidersCurrentEmployee();
  const query = new URLSearchParams(location.search);
  const defaultBusinessSectionId =
    currentEmployee?.businessSections[0]?.id || "";
  const businessSectionId =
    query.get("business_section_id") || defaultBusinessSectionId;

  const breadCrumbItems = [
    {
      label: "チェックアウト",
      href: MyRoutes.Members.inputEmployeeSurveysIndex({
        q: { businessSectionId },
      }),
    },
    {
      label: "一覧",
      href: MyRoutes.Members.employeeSurveysIndex({
        q: { businessSectionId },
      }),
    },
  ];

  const { navigations } = useEmployeeSurveysSlideNavigation({
    businessSectionId,
    selectedArray: [false, false, true],
  });

  const { employeeSurveys, fetchNextPage, isLoading, hasNextPage } =
    useSearchMembersEmployeeSurveys();

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent navigations={navigations} withRightSidebar>
        <div className="mt-6">
          <InfiniteScrollWithMoreButton
            itemsLength={employeeSurveys.length}
            nextFetchFunction={fetchNextPage}
            hasMore={hasNextPage}
            isLoading={isLoading}
          >
            <div className="grid grid-cols-2">
              <GridHeaderCells texts={["タイトル", "期間"]} />
              {employeeSurveys.map((employeeSurvey) => (
                <GridRow
                  key={employeeSurvey.id}
                  lists={[
                    employeeSurvey.title,
                    `${employeeSurvey.startDate}〜${employeeSurvey.endDate || "なし"}`,
                  ]}
                  openedMenu={
                    <OpenedMenuInGrid
                      cols={2}
                      buttons={[
                        {
                          text: "確認",
                          link: MyRoutes.Members.employeeSurveysEmployeeSurveySubmissionsIndex(
                            {
                              employeeSurveyId: employeeSurvey.id,
                              q: { businessSectionId },
                            },
                          ),
                        },
                      ]}
                    />
                  }
                />
              ))}
            </div>
          </InfiniteScrollWithMoreButton>
        </div>
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar />
    </EmployeeLayout>
  );
};
