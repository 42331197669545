import React from "react";

import {
  useApisMembersEmployeeSurveySubmissionsBookmarksCreate,
  useApisMembersEmployeeSurveySubmissionsBookmarksDestroy,
  useApisMembersEmployeeSurveySubmissionsLikesCreate,
  useApisMembersEmployeeSurveySubmissionsLikesDestroy,
  useBoolean,
  useCounter,
  useFooters,
} from "~/hooks";

import { Button } from "~/components/atoms";
import {
  EmployeeSurveyQuestionDetail,
  EmployeeSurveySubmissionsLikedEmployeesModal,
  PostFooter,
  PostHeader,
} from "~/components/organisms";

import { EmployeeSurveySubmissionType } from "~/domains";

type PropsType = {
  employeeSurveySubmission: EmployeeSurveySubmissionType;
  className?: string;
  openReply: (value: string) => void;
};

export const EmployeeSurveySubmissionDetail = ({
  employeeSurveySubmission,
  openReply,
  className = "",
}: PropsType) => {
  const { isChecked: isOpen, setTrue, setFalse } = useBoolean(false);
  const {
    isChecked: liked,
    setTrue: setLiked,
    setFalse: setNotLiked,
  } = useBoolean(employeeSurveySubmission.liked);
  const {
    count: likeCount,
    increment: likeIncrement,
    decrement: likeDecrement,
  } = useCounter(
    employeeSurveySubmission.employeeSurveySubmissionStat.likesCount,
  );
  const {
    isChecked: bookmarked,
    setTrue: setBookmarked,
    setFalse: setNotBookmarked,
  } = useBoolean(employeeSurveySubmission.bookmarked);
  const {
    count: bookmarkCount,
    increment: bookmarkIncrement,
    decrement: bookmarkDecrement,
  } = useCounter(
    employeeSurveySubmission.employeeSurveySubmissionStat.bookmarksCount,
  );
  const {
    isChecked: isLikedEmployeesModalOpen,
    setTrue: setLikedEmployeesModalOpen,
    setFalse: setLikedEmployeesModalClose,
  } = useBoolean(false);

  const { mutate: likeCreateRequest } =
    useApisMembersEmployeeSurveySubmissionsLikesCreate();
  const { mutate: likeDeleteRequest } =
    useApisMembersEmployeeSurveySubmissionsLikesDestroy();

  const { mutate: bookmarkCreateRequest } =
    useApisMembersEmployeeSurveySubmissionsBookmarksCreate();
  const { mutate: bookmarkDeleteRequest } =
    useApisMembersEmployeeSurveySubmissionsBookmarksDestroy();

  const handleCreateLike = () => {
    likeCreateRequest(
      {
        employeeSurveySubmissionId: employeeSurveySubmission.id,
      },
      {
        onSuccess: () => {
          setLiked();
          likeIncrement();
        },
      },
    );
  };

  const handleDeleteLike = () => {
    likeDeleteRequest(
      {
        employeeSurveySubmissionId: employeeSurveySubmission.id,
      },
      {
        onSuccess: () => {
          setNotLiked();
          likeDecrement();
        },
      },
    );
  };

  const handleCreateBookmark = () => {
    bookmarkCreateRequest(
      {
        employeeSurveySubmissionId: employeeSurveySubmission.id,
      },
      {
        onSuccess: () => {
          setBookmarked();
          bookmarkIncrement();
        },
      },
    );
  };

  const handleDeleteBookmark = () => {
    bookmarkDeleteRequest(
      {
        employeeSurveySubmissionId: employeeSurveySubmission.id,
      },
      {
        onSuccess: () => {
          setNotBookmarked();
          bookmarkDecrement();
        },
      },
    );
  };

  const leftFooters = useFooters({
    like: {
      doneAlready: liked,
      undoOnClick: handleDeleteLike,
      doOnClick: handleCreateLike,
      count: likeCount,
      textOnClick: setLikedEmployeesModalOpen,
    },
    bookmark: {
      doneAlready: bookmarked,
      undoOnClick: handleDeleteBookmark,
      doOnClick: handleCreateBookmark,
      count: bookmarkCount,
    },
    comment: {
      count: employeeSurveySubmission.employeeSurveySubmissionStat.repliesCount,
      undoOnClick: () => openReply(employeeSurveySubmission.id),
      doOnClick: () => openReply(employeeSurveySubmission.id),
      doneAlready: Boolean(
        employeeSurveySubmission.employeeSurveySubmissionStat.repliesCount,
      ),
    },
  });

  const questions = isOpen
    ? employeeSurveySubmission.employeeSurvey.employeeSurveyQuestions
    : employeeSurveySubmission.employeeSurvey.employeeSurveyQuestions.slice(
        0,
        1,
      );
  return (
    <div className={`flex flex-col ${className}`}>
      <PostHeader
        postedEmployee={employeeSurveySubmission.employee}
        miniText={employeeSurveySubmission.postDate}
        underLabel={employeeSurveySubmission.employeeSurvey.title}
        menuItems={[
          {
            text: isOpen ? "閉じる" : "もっと見る",
            onClick: isOpen ? setFalse : setTrue,
          },
        ]}
      />
      <div className="mt-8 space-y-2">
        <div className="space-y-8">
          {questions.map((question) => (
            <EmployeeSurveyQuestionDetail
              key={question.id}
              question={question}
              options={question.employeeSurveyQuestionOptions || []}
              answers={
                employeeSurveySubmission.employeeSurveySubmissionAnswers?.map(
                  (a) => ({
                    employeeSurveyQuestionId: a.employeeSurveyQuestion.id,
                    employeeSurveyQuestionOptionId:
                      a.employeeSurveyQuestionOption?.id,
                    answer: a.answer,
                  }),
                ) || []
              }
            />
          ))}
        </div>
        {isOpen ? (
          <Button
            text="閉じる"
            onClick={setFalse}
            color="gray"
            outline
            className="w-full"
            size="sm"
          />
        ) : (
          <Button
            text="もっと見る"
            onClick={setTrue}
            color="gray"
            outline
            className="w-full"
            size="sm"
          />
        )}
      </div>
      <PostFooter iconWithCounters={leftFooters.footers} className="mt-3" />
      {isLikedEmployeesModalOpen && (
        <EmployeeSurveySubmissionsLikedEmployeesModal
          employeeSurveySubmissionId={employeeSurveySubmission.id}
          onClose={setLikedEmployeesModalClose}
        />
      )}
    </div>
  );
};
